import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../components/scss/app.scss"

const ADPage = () => (
    <Layout>
    <SEO title="Advanced Technology – Best Cosmetic Dentistry Clinic in Toronto"
     description="Achieving the best cosmetic dentistry results is a combination of skill, experience and technology. We use the latest world renowned technology to deliver results."
      keywords="Cosmetic Dentistry" />
    <main className="site-content" role="main">

<section id="main-top-img" className="advanced hidden-xs">
  <div className="container">
    <div className="row">
      <div className="col-sm-12 sec-title wow animated fadeInDown">
        <h1>Advanced Technology</h1>
        <h2>How do we use it?</h2>
      </div>
    </div>
  </div>
</section>

<section id="main-white">
  <div className="container">
    <div className="row">
      <div className="col-sm-8 wow animated fadeInRight">
        <h2>Laser Dentistry</h2>
        <p><strong>How do we use</strong><strong> lasers </strong><strong>in our practice?</strong></p>
        <p>Glad you asked! Dental lasers can be used to:</p>
        <ul>
          <li>Reduce the discomfort of canker and cold sores.</li>
          <li>Expose partially erupted wisdom teeth.</li>
          <li>Remove muscle attachments that limit proper movement.</li>
          <li>Manage gum tissue during impressions for crowns or other procedures.</li>
          <li>Remove overgrown tissues caused by certain medications.</li>
          <li>Perform biopsy procedures.</li>
          <li>Remove inflamed gum tissues and aid in the treatment of gum disease.</li>
          <li>Remove or reshape gum and bone tissues during crown lengthening procedures.</li>
          <li>Help treat infections in root canals.</li>
          <li>Speed up tooth whitening procedures.</li>
        </ul>
        <h2 className="adv__heading">Oral CDX Brush Biopsy</h2>
        <p>The OralCDx BrushTest® allows the dentist to painlessly test common red or white spots in the mouth, ensuring that these spots do not contain any pre-cancerous or cancerous cells. The technology used also helps the professional to determine the cause of these spots.</p>
        <p><a className="btn btn-black" href="https://www.cdxdiagnostics.com/resources-for-your-doctor-for-patients/" target="_blank">Read More</a></p>
        <h2 className="adv__heading">Velscope Oral Cancer Screening</h2>
        <p>The VELscope® Vx Enhanced Oral Assessment System helps the dentist to visualize any abnormalities in the oral tissues, such as cancer and pre-cancer, in about two minutes. This handheld device emits a blue light that causes healthy and unhealthy oral tissues to fluoresce in different ways.</p>
        <p><a className="btn btn-black" href="https://cambiemarinegatewaydentist.com/general-dentistry/velscope-oral-cancer-screening/" target="_blank">Read More</a></p>
        <h2 className="adv__heading">Oravital</h2>
        <p>The OraVital® System uses microbiology testing to accurately detect the specific bacteria responsible for the patient’s oral condition. All that’s required from the patient are oral swabs and/or paper points.</p>
        <p><a className="btn btn-sm btn-black" href="http://www.oravitalpro.com/ovs-lab.php" target="_blank">Read More</a></p>
        <h2 className="adv__heading">Inside The Mouth Cameras</h2>
        <p>A dental camera, or intraoral camera, is a handheld pen-like device with a camera attached to its tip. This device allows the dentist to show their patient clear images of the inside of their mouth during their appointment, and improves communication between the dentist and patient.</p>
      </div>
      <div className="col-sm-4 wow animated zoomIn text-center" data-wow-delay="0.6s">
        <a href="/virtual-smile-makeover"><img src={require("../images/desktop/services/get-virtual-smile.png")} alt="Virtual Smile Makeover" className="img-responsive" /></a>
      </div>
    </div>
  </div>
</section>
</main>
  </Layout>
)

export default ADPage